import FooterLarge from "@notus-pro/react/FooterLarge";

const footerProps = {
  title: "Let's keep in touch!",
  description:
    "Find us on any of these platforms, we respond 1-2 business days.",
  copyright: "Copyright © 2021 ZetaMinusOne",
  socials: [
    { icon: "twitter", link: "https://twitter.com/ZetaMinusOne/" },
    { icon: "github", link: "https://github.com/ZetaMinusOne" },
  ],
  links: [
    {
      name: "Useful Links",
      items: [
        {
          href: "https://github.com/ZetaMinusOne",
          target: "_blank",
          children: "Github",
        },
        {
          href: "/contact",
          target: "_blank",
          children: "Contact Us",
        },
      ],
    },
  ],
};

function Footer() {
  return <FooterLarge {...footerProps} />;
}

export default Footer;
