import { useUser } from "@auth0/nextjs-auth0";
import NavbarLinks from "@notus-pro/react/NavbarLinks";
import Head from "next/head";

const navbarProps = {
  color: "dark",
  type: "fixed",
  logoText: "PolicyGuard",
  logoLink: { href: "/" },
  socials: [
    {
      icon: "github",
      text: "Star on Github",
      link: "https://github.com/zetaminusone",
    },
  ],
  leftLinks: [
    { href: "/how", children: "How it Works" },
    { href: "/pricing", children: "Pricing" },
  ],
  rightLinks: [{ href: "/api/auth/login", children: "Sign In" }],
};

function Header() {
  const { user, isLoading } = useUser();
  if (!isLoading && user) {
    navbarProps.rightLinks = [
      { href: "/app", children: "Dashboard" },
      { href: "/api/auth/logout", children: "Logout" },
    ];
  }
  return (
    <>
      <Head>
        <title>PolicyGuard - Web Security and Privacy</title>
      </Head>
      <NavbarLinks {...navbarProps} />
    </>
  );
}

export default Header;
