import type { NextPage } from 'next'
import "@notus-pro/react/tailwind.min.css"

import HeaderNewsletterIllustration from "../components/notus/HeaderNewsletterIllustration"
import { useState } from 'react'
import { useRouter } from 'next/dist/client/router'
import Footer from '../components/layout/landing/Footer'
import Header from '../components/layout/landing/Header'


const headerProps = {
  title: "Security and privacy best practices don't need to be hard...",
  description:
    "Our security platform allows you to quickly and easily deploy defense in depth against cross site scripting and other website vulnerabilies, all while following privacy best practices.",
  image: "/zen-park.svg",
  button: { color: "pink", children: "Join Beta", href: ""},
}



const Home: NextPage = () => {
  headerProps.button.href = "/api/auth/signup";

  return (
    <>
      <Header />
      <section className="relative pb-20">
        <div className="container mx-auto px-4 pt-20">
          <HeaderNewsletterIllustration {...headerProps} />
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Home
